import { Components } from '@readme/markdown';
import React, { forwardRef, useCallback, useState } from 'react';

import useUniqueId from '@core/hooks/useUniqueId';

import Flex from '@ui/Flex';
import FormGroup from '@ui/FormGroup';
import Input from '@ui/Input';
import Menu from '@ui/Menu';
import MenuDivider from '@ui/Menu/Divider';
import MenuItem from '@ui/Menu/Item';

import classes from './style.module.scss';

const { Anchor } = Components;

export interface LinkEditorProps {
  /**
   * A base url for all links
   */
  baseUrl?: string;
  /**
   * An optional footer
   */
  footer?: React.ReactNode;
  /**
   * Initial value for the label input
   */
  label?: string;
  /**
   * Callback fired on a change event
   */
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  /**
   * Callback fired on a submit event
   */
  onSubmit?: (e: React.FormEvent, data: { label: string; url: string }) => void;

  /**
   * Indicates whether all input fields are required or not. If required, input
   * validity constraints must be met before the form can be submit. Error
   * messages are shown in the UI to help users know what to do.
   */
  required?: boolean;

  /**
   * Initial value for the url input
   */
  url?: string;
}

/**
 * A menu to add/edit a name and a URL for a link.
 * Note that our editor relies on this component.
 */
const LinkEditor = forwardRef<HTMLElement, LinkEditorProps>(
  ({ baseUrl = '', onSubmit, onChange, url, label, footer, required }: LinkEditorProps, ref) => {
    const uid = useUniqueId('LinkEditor');
    const [formError, setFormError] = useState<Record<'label' | 'url', string | null>>({
      label: null,
      url: null,
    });

    /**
     * Check validity of our form inputs and returns `true` if every input
     * satisfies their validation constraints.
     */
    const reportValidity = useCallback((labelValue: string, urlValue: string) => {
      setFormError({
        label: labelValue ? null : 'Label is required',
        url: urlValue ? null : 'URL is required',
      });
      return labelValue && urlValue;
    }, []);

    const handleSubmit = useCallback(
      e => {
        e.preventDefault();
        e.stopPropagation();
        const formElement = e.currentTarget;
        const labelValue = formElement.querySelector('input[name=label]')?.value || '';
        const urlValue = formElement.querySelector('input[name=url]')?.value || '';

        if (required && !reportValidity(labelValue, urlValue)) return;

        onSubmit?.(e, { label: labelValue, url: urlValue });
      },
      [onSubmit, reportValidity, required],
    );

    return (
      <Flex ref={ref} align="stretch" layout="col" onSubmit={handleSubmit} tag="form">
        <Menu className={classes.LinkEditor}>
          <MenuItem focusable={false} TagName="div">
            <FormGroup errorMessage={formError.label} htmlFor={uid('label')} label="Label">
              <Input
                id={uid('label')}
                name="label"
                onChange={onChange}
                placeholder="label"
                size="sm"
                value={label || ''}
              />
            </FormGroup>
          </MenuItem>
          <MenuDivider />
          <MenuItem focusable={false} TagName="div">
            <FormGroup errorMessage={formError.url} htmlFor={uid('url')} label="URL">
              <Input id={uid('url')} name="url" onChange={onChange} placeholder="url" size="sm" value={url || ''} />
            </FormGroup>
          </MenuItem>
          {!!url && (
            <>
              <MenuDivider />
              <MenuItem
                color="blue"
                href={Anchor.getHref(url, baseUrl.replace(/\/$/, ''))}
                TagName="a"
                target="_blank"
                title={url}
              >
                <span className={classes['LinkEditor-link']}>{url}</span>
              </MenuItem>
            </>
          )}
          {footer || <button style={{ display: 'none' }} />}
        </Menu>
      </Flex>
    );
  },
);

LinkEditor.displayName = 'LinkEditor';

export default LinkEditor;
