import type CodeMirror from 'codemirror';
import type { ForwardedRef } from 'react';

import React, { forwardRef, useImperativeHandle, useRef } from 'react';

import useClassy from '@core/hooks/useClassy';
import useMediaQuery from '@core/hooks/useMediaQuery';

import CodeSnippet from '@ui/CodeSnippet';

import classes from './index.module.scss';

export interface CodeInputProps {
  className?: string;
  /**
   * Reference to the underlying CodeMirror.Editor instance, which grants you
   * access to its Programming API.
   * @link https://codemirror.net/2/doc/manual.html#api
   */
  codeEditorRef?: React.MutableRefObject<ReturnType<typeof CodeMirror> | null>;
  footer?: React.ReactElement;
  header?: React.ReactElement;
  initialValue?: string | null | undefined;
  isDarkMode?: boolean;
  language?: string;
  onChange?: (value: string) => void;
  options?: object;
  size?: 'lg' | 'md' | 'sm';
}

const CodeInput = forwardRef(function CodeInput(
  {
    className,
    codeEditorRef,
    footer,
    header,
    initialValue = '',
    isDarkMode,
    language = 'html',
    onChange,
    options,
    size = 'md',
  }: CodeInputProps,
  forwardedRef: ForwardedRef<HTMLDivElement | null>,
) {
  const ref = useRef<HTMLDivElement>(null);
  const bem = useClassy(classes, 'CodeInput');
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  // Respect the `isDarkMode` prop if it is provided, otherwise use the user's system preference
  const darkMode = isDarkMode ?? prefersDarkMode;

  useImperativeHandle(forwardedRef, () => ref.current as HTMLDivElement);

  return (
    <div
      aria-multiline="true"
      className={bem('&', `_${size}`, className)}
      data-testid="code-input"
      onFocus={() => {
        // When wrapper recieves focus we want to transfer focus the <CodeSnippet>'s editable element
        ref?.current?.querySelector('textarea')?.focus();
      }}
      role="textbox"
      tabIndex={0}
    >
      <CodeSnippet
        ref={ref}
        className={bem('-code-snippet')}
        code={initialValue ?? ''}
        editorProps={{
          editorDidMount: editor => {
            if (!codeEditorRef) return;
            codeEditorRef.current = editor;
          },
          onChange: (_, __, data) => {
            onChange?.(data);
          },
          onKeyDown: (_, event) => {
            // When the user presses the escape key we want to blur focus,
            // to prevent trapping keyboard navigation inside the <CodeSnippet>'s editable element
            if (event.key === 'Escape') {
              ref?.current?.querySelector('textarea')?.blur();
            }
          },
        }}
        footer={footer}
        header={header}
        language={language}
        options={{ ...options, editable: true, dark: darkMode, readOnly: false, foldGutter: true }}
      />
    </div>
  );
});

export default CodeInput;
