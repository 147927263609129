import type { NextPageLink } from '@readme/backend/models/page/types';

import React, { memo, forwardRef, useCallback } from 'react';

import classy from '@core/utils/classy';

import Box from '@ui/Box';
import Menu, { MenuItem } from '@ui/Menu';
import Tooltip from '@ui/Tooltip';

import classes from './style.module.scss';

/**
 * Indicates how tall each `PageMenuItem` row is. This is useful when rendering
 * these items inside a virtual list.
 * @example
 * <PageMenu>
 *   <FixedSizeList itemSize={pageMenuItemHeight} ...>
 *     ...
 *   </FixedSizeList>
 * </PageMenu>
 */
export const pageMenuItemHeight = 30;

/**
 * Indicates what the fixed `PageMenu` height is. This is useful when rendering
 * this as a virtual list.
 * @example
 * <PageMenu>
 *   <FixedSizeList height={pageMenuHeight} width="100%" ...>
 *     ...
 *   </FixedSizeList>
 * </PageMenu>
 */
export const pageMenuHeight = pageMenuItemHeight * 5;

type MenuItemClickHandler = (result: NextPageLink) => void;

type ItemProps = NextPageLink & {
  onClick?: MenuItemClickHandler;
  selected?: boolean;
  style?: React.CSSProperties;
};

export const PageMenuItem = ({ category, name, onClick: onClickHandler, selected, slug, style, type }: ItemProps) => {
  const onClick = useCallback(
    () => onClickHandler?.({ name, slug, type, category }),
    [category, name, onClickHandler, slug, type],
  );
  const className = classy(classes.PageMenuItem, selected && 'selected');

  return (
    <MenuItem
      key={`${name}-${type}:${slug}`}
      aria-current={selected}
      className={className}
      data-name={name}
      onClick={onClick}
      role="menuitem"
      style={style}
    >
      <div className={classes['PageMenuItem-Container']}>
        <Tooltip asTitle content={name} delay={[300, 200]} placement="top-start">
          <p className={classes['PageMenuItem-Container-name']}>{name}</p>
        </Tooltip>
        <Tooltip asTitle content={category} delay={[300, 200]} placement="top-start">
          <p className={classes['PageMenuItem-Container-category']}>{category}</p>
        </Tooltip>
      </div>
    </MenuItem>
  );
};

export interface Props {
  children?: React.ReactNode;
  className?: string;
}

/**
 * Menu Content (container)
 * @todo Virtualize this list for the case when there are 1000+ page items.
 * @link https://linear.app/readme-io/issue/RM-11694/md-editors-page-menu-is-bloated-when-sidebar-data-set-is-very-large
 */
const PageMenu = memo(
  forwardRef<HTMLElement, Props>(({ className, children }, ref) => {
    return (
      <Box className={classy(classes['PageMenu-Container'], className)} kind="pop">
        <Menu
          ref={ref}
          className={classes['PageMenu-Menu']}
          data-testid="link-menu"
          role="menu"
          style={{ maxHeight: `${pageMenuHeight}px` }}
        >
          {children}
        </Menu>
      </Box>
    );
  }),
);

export default PageMenu;
